import React from 'react';
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";

import { currentLocale, switchLocale } from '../locales';
import {countries} from '../locales/countries'


export default function LanguageSwitch() {
    const { t } = useTranslation();
    return (
        <>
            <Autocomplete
                disableClearable={true}
                onChange={(event, value) => switchLocale(value.code)}
                value={countries.find(country => country.code === currentLocale())}
                options={countries}
                size='small'
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.flag.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.flag.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <span>
                    <TextField
                        {...params}
                        label={t('Vyberte jazyk')}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  width={20}
                                  src={`https://flagcdn.com/w20/${countries.find(country => country.code === currentLocale()).flag.toLowerCase()}.png` || `https://flagcdn.com/w20/${countries[0].flag.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${countries.find(country => country.code === currentLocale()).flag.toLowerCase()}.png 2x`|| `https://flagcdn.com/w40/${countries[0].flag.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                              </InputAdornment>
                            )
                          }}
                    />
                    </span>
                )}
            />
        </>
    );
}