import React from 'react';
import axiosURL from '../../utils/axiosURL';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
    useForm,
    FormProvider,
} from "react-hook-form";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import LanguageSwitch from '../../components/LanguageSwitch';
import ContactForm from './ContactForm';
import ReservationForm from './ReservationForm';
import { AppBar, Container, CssBaseline, Grid, Toolbar, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { currentLocale } from '../../locales';

import StatusPageForm from './StatusPageForm';


function getStepContent(step) {
    switch (step) {
        case 0: return <ReservationForm />;
        case 1: return <ContactForm />;
        default: throw new Error('Unknown step');
    }
}

function nearestFutureMinutes(interval, someMoment) {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

const defaultValues = {
    firstName: '',
    lastName: '',
    phone: '+420',
    email: '',
    from_date: nearestFutureMinutes(15, moment().add(1, 'hour')),
    from_time: '',
    to_date: nearestFutureMinutes(15, moment().add(15, 'minutes')),
    to_date_interval: 1800,
    max_interval: 18000,
    quantity: '',
    note: '',
    gdpr: false,
    privacy_policy: false,
}

function ReservationCreate(props) {
    const { t } = useTranslation()
    const { token } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = createTheme({ palette: { mode: ['dark', 'light'].includes(searchParams.get('mode')) ? `${searchParams.get('mode')}` : 'light' } });
    const { reset } = useForm();
    const [errors, setErrors] = React.useState({});

    const [location, setLocation] = React.useState()

    const methods = useForm({
        defaultValues: defaultValues,
    });

    const [status, setStatus] = React.useState({
        open: false,
        message: '',
        severity: ''
    })

    const loadLocation = async () => {
        axiosURL.get(`api/${token}/location?loc=${searchParams.get('loc')}`).then(res => {
            methods.setValue("to_date_interval", res.data.location.reservation_default_time || 1800)
            methods.setValue("max_interval", res.data.location.reservation_max_time || 18000)
            setLocation(res.data.location.name)
        }).catch(err => {
            console.log(err)
        })
    };

    React.useEffect(() => {
        loadLocation()
    }, []);

    const steps = [t("stepper.reservationinfo"),t("stepper.contactinfo")];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (data) => {
        if (activeStep == steps.length - 1) {
            axiosURL.post(`api/${token}/reservation/create?loc=${searchParams.get('loc')}`, {
                customer_phone: data.phone,
                customer_name: data.firstName,
                customer_surname: data.lastName,
                customer_email: data.email,
                from_date: moment(data.from_date).startOf('day').utcOffset(0, true).add(data.from_time >= 86400 ? data.from_time - 86400 : data.from_time, 'seconds').utcOffset(0, true).format(),
                to_date: moment(data.from_date).startOf('day').utcOffset(0, true).add(data.from_time >= 86400 ? data.from_time - 86400 : data.from_time, 'seconds').add(data.to_date_interval, 'seconds').utcOffset(0, true).format(),
                quantity: data.quantity,
                note: data.note,
                lang: currentLocale()
            }).then(res => {
                setStatus({
                    open: true,
                    message: t('alert.success.reservation'),
                    severity: 'success'
                })
                reset(methods.reset(defaultValues))
                setActiveStep(0);
            }).catch(err => {
                setStatus({
                    open: true,
                    message: t('alert.error.reservation'),
                    severity: 'error'
                })
                console.log(err)
            })
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleNext)} noValidate>
                        <Container maxWidth="xxl">

                            <Typography textAlign='center' variant='h6' gutterBottom>{`${location}`}</Typography>

                            <LanguageSwitch />

                            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {getStepContent(activeStep)}
                            <CssBaseline />

                            <Toolbar />

                        </Container>

                        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}>
                            <Toolbar>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='outlined'
                                            //color='warning'
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            fullWidth
                                        >
                                            {t('form.button.back')}
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            //color="primary"
                                            // onClick={handleNext}
                                            type="submit"
                                            fullWidth
                                        >
                                            {activeStep === steps.length - 1 ? t('form.button.book') : t('form.button.next')}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Toolbar>
                        </AppBar>

                    </form>
                </FormProvider>

                <StatusPageForm open={status.open} severity={status.severity} message={status.message} onClose={() => setStatus({ ...status, open: false })} />

            </ThemeProvider>

        </>
    );
}

export default ReservationCreate;