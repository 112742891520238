import React from 'react';
import { BrowserRouter } from "react-router-dom";

import ReservationFormsRoutes from './ReservationFormsRoutes';

function Router(props) {
    return (
        <>
            <BrowserRouter forceRefresh={true}>
                <ReservationFormsRoutes />
            </BrowserRouter>
        </>
    );
}

export default Router