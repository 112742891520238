import React from 'react';
import { useParams } from "react-router-dom"
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import axiosURL from '../../utils/axiosURL';

import { Box, Button, Container, Grid, Paper, Rating, TextField, Typography } from '@mui/material';
import LanguageSwitch from '../../components/LanguageSwitch';
import RatesComplete from './RatesComplete';
import RatesError from './RatesError';

function RatesCreate(props) {
    const { t } = useTranslation()
    let { token } = useParams()

    const [response, setResponse] = React.useState()

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            star: 3
        }
    });

    const handleOnSubmit = async (data) => {
        axiosURL.post(`api/${token}/reservation//rate/create`, {
            rate: data.star,
            note: data.note,
        }).then((res) => {
            console.log(res)
            setResponse(res)
        }).catch((err) => {
            setResponse(err)
            console.log(err)
        })
    };

    return (
        <Container component="main" maxWidth="xxl" sx={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
            <Paper elevation={5} sx={{ p: 2, margin: 'auto', width: 'auto' }} >
                {!response ?
                    <>
                        <LanguageSwitch />

                        <Box m={2} textAlign='center'>
                            <Typography fontWeight='bold' variant='h5'>{t('formTitleText', { keyPrefix: 'rate' })}</Typography>
                            <Typography>{t('formInfoText', { keyPrefix: 'rate' })}</Typography>
                        </Box>

                        <Box component="form" noValidate onSubmit={handleSubmit(handleOnSubmit)}>

                            <Grid container spacing={1}>
                                <Grid item xs={12} textAlign='center'>
                                    <Controller
                                        control={control}
                                        name="star"
                                        id="star"
                                        rules={
                                            { required: t('helpertext.error.required', 'required') }
                                        }
                                        render={({ field }) => (
                                            <Rating name="rating" {...field} precision={1} />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="note"
                                        id="note"
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                rows={5}
                                                size='small'
                                                label={t('form.input.note')}
                                                autoComplete="given-name"
                                                fullWidth
                                                {...field}
                                                error={Boolean(errors?.firstname)}
                                                helperText={errors.firstname?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Box my={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    {t('form.button.submit').toLowerCase()}
                                </Button>
                            </Box>
                        </Box>
                    </>
                    : response?.status == 201 ? <RatesComplete /> : <RatesError />}
            </Paper>
        </Container>
    );
}

export default RatesCreate;