import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";

import MuiPhoneNumber from 'material-ui-phone-number-2';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Typography } from '@mui/material';
import axiosURL from '../../utils/axiosURL';
import { currentLocale } from '../../locales';

function ContactForm(props) {
    const { t } = useTranslation()
    const { token } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    const [template, setTemplate] = React.useState()

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    // const validatePhone = (value) => {
    //     console.log(value)
    //     let err
    //     if (value.length < 5) err = t('helpertext.error.required')
    //     return (err)
    // };

    const loadLocationTemplate = async () => {
        console.log(currentLocale())
        axiosURL.get(`api/${token}/location/template`, {
            params: {
                loc:searchParams.get('loc'),
                lang: currentLocale()
            }
        }).then(res => {
            setTemplate(res.data.template)
        }).catch(err => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        loadLocationTemplate()
    }, [t])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="firstName"
                        rules={{ required: t('helpertext.error.required') }}
                        render={({ field }) => (
                            <TextField
                                id="first-name"
                                label={t('form.input.firstName')}
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                {...field}
                                error={Boolean(errors?.firstName)}
                                helperText={errors.firstName?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="lastName"
                        rules={{ required: t('helpertext.error.required') }}
                        render={({ field }) => (
                            <TextField
                                //required
                                id="last-name"
                                label={t('form.input.lastName')}
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                {...field}
                                error={Boolean(errors?.lastName)}
                                helperText={errors.lastName?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="phone"
                        rules={{
                            required: t('helpertext.error.required'),
                            // validate: (value) => validatePhone(value)
                            minLength: {
                                value: 5,
                                message: t('helpertext.error.short')
                            }
                        }}
                        render={({ field: { ref, ...field } }) => (
                            <MuiPhoneNumber
                                fullWidth
                                defaultCountry="cz"
                                regions={'europe'}
                                countryCodeEditable={false}
                                //name='phone'
                                //type='tel'
                                type='text'
                                label={t('form.input.phone')}
                                required
                                variant='outlined'
                                size='small'
                                {...field}
                                inputProps={{ ref: ref }}
                                error={Boolean(errors?.phone)}
                                helperText={errors.phone?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: t('helpertext.error.required'),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t('helpertext.error.emailvalid'),
                            },
                            // validate: (val) => {
                            //     if (watch('passwordConfirm') != val) {
                            //         return t('helpertext.error.emailConfirm');
                            //     }
                            // }
                        }}
                        render={({ field }) => (
                            <TextField
                                name="email"
                                label={t('form.input.email')}
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                {...field}
                                error={Boolean(errors?.email)}
                                helperText={errors.email?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="emailConfirm"
                        rules={{
                            required: t('helpertext.error.required'),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t('helpertext.error.emailvalid'),
                            },
                            validate: (val) => {
                                if (watch('email') != val) {
                                    return t('helpertext.error.emailConfirm');
                                }
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                name="emailConfirm"
                                label={t('form.input.emailConfirm')}
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                {...field}
                                error={Boolean(errors?.emailConfirm)}
                                helperText={errors.emailConfirm?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormGroup>

                        <FormControl error={Boolean(errors?.gdpr)}>
                            <FormControlLabel control={
                                <Controller
                                    control={control}
                                    name="gdpr"
                                    //rules={{ required: t('helpertext.error.required') }}
                                    render={({ field }) => (
                                        <Checkbox {...field} />
                                    )}
                                />
                            } label="Souhlasím s GDPR a dávám souhlas se zasíláním marketingových zpráv na můj e-mail." />
                            <FormHelperText>{errors.gdpr?.message}</FormHelperText>
                        </FormControl>

                        <FormControl error={Boolean(errors?.privacy_policy)}>
                            <FormControlLabel control={
                                <Controller
                                    control={control}
                                    name="privacy_policy"
                                    rules={{ required: t('helpertext.error.required') }}
                                    render={({ field: { ref, ...field } }) => (
                                        <Checkbox {...field} inputRef={ref} />
                                    )}
                                />
                            } label="* Přijmout zásady ochrany osobních údajů" />
                            <FormHelperText>{errors.privacy_policy?.message}</FormHelperText>
                        </FormControl>

                        <Typography whiteSpace='pre-wrap' variant='caption'>{template?.footer}</Typography>

                    </FormGroup>

                </Grid>

            </Grid>
        </>
    );
}

export default ContactForm;