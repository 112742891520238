import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';

function MessageDialog(props) {
    const { t } = useTranslation()

    return (
        <>
            <Dialog open={props.open}>
                <DialogTitle>
                    <Alert severity={props.severity} sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                </DialogTitle>

                {/* <DialogContentText>{props.message}</DialogContentText> */}
                {/* <DialogActions>
                </DialogActions> */}
            </Dialog>
        </>
    );
}

export default MessageDialog;