import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from 'i18next-browser-languagedetector';

import translationCZ from './translations/cs/translation.json';
import translationEN from './translations/en/translation.json';
import translationDE from './translations/de/translation.json';

import { countries } from "./countries";
import { currentLocale } from ".";

i18n
    //.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: currentLocale(),

        resources: {
            cs: {
                translation: translationCZ
            },
            en: {
                translation: translationEN
            },
            de: {
                translation: translationDE
            },
        },
        //keySeparator: false, // we do not use keys in form messages.welcome
        fallbackLng: countries[0].code, //switchLocale(countries[0].code),
        //debug: true,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;