import React, { Suspense } from 'react';
import { Route, Routes } from "react-router-dom"
import { CircularProgress } from '@mui/material'

import ReservationCreate from '../pages/Reservations/ReservationCreate';
import RatesCreate from '../pages/Rates/RatesCreate';
import ReservationEdit from '../pages/Reservations/ReservationEdit';

function ReservationFormsRoutes(props) {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Routes>
                <Route
                    exact path='/reservation/:token'
                    element={
                        <ReservationCreate />
                    } />

                <Route
                    exact path='/reservation/edit/:token'
                    element={
                        <ReservationEdit />
                    } />

                <Route
                    exact path='/rates/:token'
                    element={
                        <RatesCreate />
                    } />

            </Routes>
        </Suspense>
    );
}

export default ReservationFormsRoutes;