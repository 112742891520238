import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Typography } from '@mui/material';

function RatesComplete(props) {
    const { t } = useTranslation()

    return (
        <Box minWidth={250}>
            <Box m={2} textAlign='center'>
            <Typography fontWeight='bold' variant='h5'>{t('headText', { keyPrefix: 'alert.success.rate' })}</Typography>
                <Typography fontWeight='bold'>{t('primaryText', { keyPrefix: 'alert.success.rate' })}</Typography>
                <Typography>{t('secondaryText', { keyPrefix: 'alert.success.rate' })}</Typography>
            </Box>

            <Box m={2}>
                <VerifiedUserIcon sx={{ textAlign: 'center', width: '100%', fontSize: 100 }} color="success" />
            </Box>

            {/* <Box m={2}>
                <Button
                    component={Link}
                    to='/login'
                    fullWidth
                    variant="contained"
                >
                    {t('button.sign_in', 'Sign in').toLowerCase()}
                </Button>
            </Box> */}
        </Box>
    );
}

export default RatesComplete;