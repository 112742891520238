import Cookies from 'universal-cookie';
import i18n from "i18next";

import {countries} from '../locales/countries'

const LOCALE_COOKIE = 'locale';
const cookies = new Cookies();

/*
 * Initializes the I18n support for the current locale (e.g. "en-US").
 * The locale is read from a cookie, which gets initialized with the default
 * locale of the web browser.
 */
export const initialize = () => {
  const locale = cookies.get(LOCALE_COOKIE);
}

/*
 * Switch the locale by changing the locale cookie and reloading the page.
 */
export const switchLocale = locale => {
  //setLocaleCookie(locale);
  localStorage.setItem('i18nextLng', locale)
  i18n.changeLanguage(locale);
};

/*
 * Returns "de-DE" for german language locales (regardless of country)
 * and "en-US" as a default fallback for all other locales.
 */
export const currentLocale = () => {
  //const locale = Storage.getItem('i18nextLng')
  const locale = localStorage.getItem('i18nextLng') || countries[0].code //cookies.get(LOCALE_COOKIE) || countries[0].code;
  return locale
};

//
const setLocaleCookie = locale => {
  cookies.set(LOCALE_COOKIE, locale, {
    maxAge: 60 * 60 * 24 * 365,
    //sameSite: true
    domain: window.location.host
  })
}

