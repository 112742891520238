import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosURL from '../../utils/axiosURL'

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
    useWatch,
} from "react-hook-form";

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/cs';
import { CircularProgress, Chip, FormControl,FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';


const locale = 'cs-CS';

function ReservationForm(props) {
    const { t } = useTranslation()
    const { token } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    // const [location, setLocation] = React.useState([])

    const { control, formState: { errors }, setValue, getValues } = useFormContext();
    const [openHourGlobal, setOpenHourGlobal] = React.useState(null);

    const [loading, setLoading] = React.useState(true);

    function hourscycle() {
        const max = control._formValues.max_interval//43200
        const step = 1800
        const rows = [];
        for (let i = step; i <= max; i += step) {
            rows.push({ value: parseInt(i), label: moment.utc(1000 * i).format('H[h] mm[m]') });
        }
        return rows;
    }

    // const loadLocationTime = async () => {
    //     axiosURL.get(`api/${token}/location?loc=${searchParams.get('loc')}`).then(res => {
    //         setLocation(res.data.location)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // };

    const loadOpenHourGlobal = async (date) => {
        setOpenHourGlobal()
        setLoading(true)
        axiosURL.get(`api/${token}/reservation/openhourglobal/filter?loc=${searchParams.get('loc')}&day=${moment(control._formValues.from_date).format('YYYY-MM-DD')}&interval=${control._formValues.to_date_interval}`).then(res => {
            setOpenHourGlobal(res.data.reservationOpenHourGlobal)
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const [error, setError] = React.useState({
        from_date: '',
        to_date: ''
    })

    const validateFromDate = (date) => {
        let err
        loadOpenHourGlobal()
        //if (!moment(date).isValid()) err = t('helpertext.error.dateFormat')
        //else if (error.from_date == 'minutesStep') err = t('helpertext.error.dateStep')
        //if (moment(date).isSameOrAfter(moment(control._formValues.to_date))) err = 'Nemůže být větší než konec'
        if (moment(date).isBefore(moment())) err = t('helpertext.error.dateBeforeToday')
        else if (error.from_date == 'shouldDisableDate') err = t('helpertext.error.reservationStop')
        return (err)
    }

    // React.useEffect(() => {
    //     if(!hourscycle().some(time => time.value === parseInt(getValues("to_date_interval"))))
    //     setValue("to_date_interval", hourscycle()[0].value)//nearestFutureMinutes(15, moment(control._formValues.from_date).add(location.reservation_default_time || 900, 'seconds')))
    // }, [location]);

    React.useEffect(() => {
        setValue("from_time", null)
        loadOpenHourGlobal()
    }, [useWatch({ name: 'to_date_interval' }), useWatch({ name: 'from_date' })]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <FormControl variant="outlined" size='small' fullWidth error={Boolean(errors?.to_date_interval)} >
                        <InputLabel>{t('form.input.reservationDuration') + ' *'}</InputLabel>
                        <Controller
                            control={control}
                            name="to_date_interval"
                            rules={{ required: t('helpertext.error.required') }}
                            render={({ field }) => (
                                <Select
                                    label={t('form.input.reservationDuration') + ' *'}
                                    value={field.value}
                                    onChange={(e) => (field.onChange(e.target.value))}
                                >
                                    {hourscycle().map((time, index) => (
                                        <MenuItem key={index} value={time?.value}>{time?.label}</MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText>{errors.to_date_interval?.message}</FormHelperText>
                    </FormControl>

                </Grid>

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="quantity"
                        rules={{ required: t('helpertext.error.required') }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                type='number'
                                required
                                label={t('form.input.quantity')}
                                InputProps={{
                                    inputProps: {
                                        pattern: "[0-9]*",
                                        min: 1,
                                        step: 1,
                                    }
                                }}
                                variant="outlined"
                                size='small'
                                {...field}
                                error={Boolean(errors?.quantity)}
                                helperText={errors.quantity?.message}
                            />
                        )}
                    />
                </Grid>

                {
                    useWatch({ name: 'quantity' }) &&
                    <>
                        <Grid item xs={12} md={5} lg={3}>

                            <FormControl fullWidth error={Boolean(errors?.from_date)} >
                                <Controller
                                    control={control}
                                    name="from_date"
                                    rules={{
                                        required: t('helpertext.error.required'),
                                        validate: (date) => validateFromDate(date)
                                    }}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                openTo="day"
                                                minDate={moment()}
                                                onMonthChange={(date) => (setValue("from_time", null))}
                                                //shouldDisableDate={(date) => shouldDisableDate(date)}
                                                value={field.value}
                                                onChange={(date) => (field.onChange(moment(date)))}
                                                onError={(err) => setError({ ...error, from_date: err })}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <FormHelperText>{errors.from_date?.message}</FormHelperText>
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} md={7} lg={9}>

                            <FormControl fullWidth error={Boolean(errors?.from_time)} >
                                <Controller
                                    control={control}
                                    name="from_time"
                                    rules={{
                                        required: t('helpertext.error.required'),
                                    }}
                                    render={({ field }) => (

                                        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                                            {loading ?
                                                <CircularProgress />
                                                :
                                                openHourGlobal?.length > 0 ?
                                                    openHourGlobal?.map((time, index) => (
                                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                                            <Chip disabled={time?.isDisabled} color={time?.date_epoch == field?.value ? 'primary' : 'default'} key={index} label={time?.date_value} onClick={() => (field.onChange(time?.date_epoch))} />
                                                        </Grid>
                                                    ))
                                                    :
                                                    <Typography>{t('form.message.reservationClosed')}</Typography>
                                            }
                                        </Grid>
                                    )}
                                />
                                <FormHelperText>{errors.from_time?.message}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </>
                }

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="note"
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={t('form.input.note')}
                                multiline
                                rows={5}
                                variant="outlined"
                                size='small'
                                sx={{ backgroundColor: 'lavender' }}
                                {...field}
                                error={Boolean(errors?.note)}
                                helperText={errors.note?.message}
                            />
                        )}
                    />
                </Grid>

            </Grid>
        </>
    );
}

export default ReservationForm;