import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
//import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ConfirmDeleteDialog(props) {
    const {t} = useTranslation()
    const { title=t('dialog.delete.title'), children=t('dialog.delete.children'), open, setOpen, onConfirm } = props;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    {t('form.button.no')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="error"
                >
                    {t('form.button.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// ConfirmDeleteDialog.defaultProps = {
//     title: 'dialog.delete.title',
//     children: 'dialog.delete.children',
//   }
export default ConfirmDeleteDialog;