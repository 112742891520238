import React from 'react';
import axiosURL from '../../utils/axiosURL';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
    useForm,
    FormProvider,
} from "react-hook-form";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import CardHeader from '@mui/material/CardHeader';

import LanguageSwitch from '../../components/LanguageSwitch';
import ContactForm from './ContactForm';
import ReservationForm from './ReservationForm';
import { AppBar, Container, CssBaseline, Grid, Toolbar } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { currentLocale } from '../../locales';

import StatusPageForm from './StatusPageForm';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';
import MessageDialog from '../../components/MessageDialog';


function getStepContent(step) {
    switch (step) {
        case 0: return <ContactForm />;
        case 1: return <ReservationForm />;
        default: throw new Error('Unknown step');
    }
}

function nearestFutureMinutes(interval, someMoment) {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

const defaultValues = {
    firstName: '',
    lastName: '',
    phone: '+420',
    email: '',
    from_date: nearestFutureMinutes(15, moment().add(1, 'hour')),
    from_time: '',
    to_date: nearestFutureMinutes(15, moment().add(15, 'minutes')),
    to_date_interval: 1800,
    max_interval: 18000,
    quantity: 1,
    note: '',
    gdpr: false,
    privacy_policy: false,
}

function ReservationEdit(props) {
    const { t } = useTranslation()
    const { token } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = createTheme({ palette: { mode: ['dark', 'light'].includes(searchParams.get('mode')) ? `${searchParams.get('mode')}` : 'light' } });
    const { reset } = useForm();
    const [errors, setErrors] = React.useState({});

    const methods = useForm({
        defaultValues: defaultValues,
    });

    const [status, setStatus] = React.useState({
        open: false,
        message: '',
        severity: ''
    })
    const [message, setMessage] = React.useState({
        open: false,
        message: '',
        severity: ''
    })
    const [openDialog, setOpenDialog] = React.useState({
        deleteReservationDialog: false
    })

    const loadReservationInfo = async () => {
        await axiosURL.get(`api/${token}/reservation`).then(res => {
            console.log(res.data)
            try {
                methods.setValue('firstName', res.data.reservation.customer.name)
                methods.setValue('lastName', res.data.reservation.customer.surname)
                methods.setValue('phone', res.data.reservation.customer.phone)
                methods.setValue('email', res.data.reservation.customer.email)
                methods.setValue('max_interval', res.data.reservation.location.reservation_max_time)
                methods.setValue('to_date_interval', moment.duration(moment(res.data.reservation.to_date).diff(moment(res.data.reservation.from_date))).asSeconds())
                methods.setValue('quantity', res.data.reservation.quantity)
                methods.setValue('note', res.data.reservation.note)
            }
            catch (err) {
                //reset()
            }
        }).catch(err => {
            console.log(err)
        })
    };

    React.useEffect(() => {
        loadReservationInfo()
    }, []);

    const steps = [t("stepper.reservationinfo"), t("stepper.contactinfo"),];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (data) => {
        if (activeStep == steps.length - 1) {
            axiosURL.post(`api/${token}/reservation/edit?loc=${searchParams.get('loc')}`, {
                customer_phone: data.phone,
                customer_name: data.firstName,
                customer_surname: data.lastName,
                customer_email: data.email,
                from_date: moment(data.from_date).startOf('day').utcOffset(0, true).add(data.from_time >= 86400 ? data.from_time - 86400 : data.from_time, 'seconds').utcOffset(0, true).format(),
                to_date: moment(data.from_date).startOf('day').utcOffset(0, true).add(data.from_time >= 86400 ? data.from_time - 86400 : data.from_time, 'seconds').add(data.to_date_interval, 'seconds').utcOffset(0, true).format(),
                quantity: data.quantity,
                note: data.note,
                lang: currentLocale()
            }).then(res => {
                setStatus({
                    open: true,
                    message: t('alert.success.reservationEdit'),
                    severity: 'success'
                })
                //reset(methods.reset(defaultValues))
                setActiveStep(0);
            }).catch(err => {
                setStatus({
                    open: true,
                    message: t('alert.error.reservation'),
                    severity: 'error'
                })
                console.log(err)
            })
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleStornoReservation = async () => {
        await axiosURL.delete(`api/${token}/reservation/delete`).then(res => {
            setMessage({...message, open:true, severity:'error', message: t('alert.delete.reservation.success')})
            console.log('smazáno')
        }).catch(err => {
            setMessage({...message, open:true, severity:'error', message: t('alert.delete.reservation.error')})
            console.log(err)
        })
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleNext)} noValidate>
                        <Container maxWidth="xxl">

                            <CardHeader
                                sx={{ textAlign: 'center' }}
                                title={`* ${t('title.reservation')} *`}
                            //subheader="September 14, 2016"
                            />

                            <LanguageSwitch />


                            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            <Button sx={{ marginBottom: 2 }} fullWidth variant='outlined' color='error' onClick={() => setOpenDialog({ ...openDialog, deleteReservationDialog: true })}>{t('form.button.cancelReservation')}</Button>

                            {getStepContent(activeStep)}
                            <CssBaseline />
                            <Toolbar />


                        </Container>

                        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}>
                            <Toolbar>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='outlined'
                                            //color='warning'
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            fullWidth
                                        >
                                            {t('form.button.back')}
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            //color="primary"
                                            // onClick={handleNext}
                                            type="submit"
                                            fullWidth
                                        >
                                            {activeStep === steps.length - 1 ? t('form.button.book') : t('form.button.next')}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Toolbar>
                        </AppBar>

                    </form>
                </FormProvider>

                <StatusPageForm open={status.open} severity={status.severity} message={status.message} onClose={() => setStatus({ ...status, open: false })} />

                <ConfirmDeleteDialog
                    open={openDialog.deleteReservationDialog}
                    setOpen={() => setOpenDialog({ ...openDialog, deleteReservationDialog: !openDialog.deleteReservationDialog })}
                    onConfirm={handleStornoReservation}
                />

                <MessageDialog  open={message.open} severity={message.severity} message={message.message} onClose={() => setMessage({ ...message, open: false })}/>

            </ThemeProvider>

        </>
    );
}

export default ReservationEdit;